<template>
	<div id="sidenav">
		<slot name="top"></slot>
		<template v-for="(item, i) in itemsEnhanced" :key="i">
			<div v-if="item.sep" class="sep"></div>
			<template v-else-if="item.children">
				<a
					:ref="item.display"
					href="#"
					class="parent"
					:class="classSelect(item.children)"
					@click.prevent="expand(item.display)"
					><BaseIcon name="expand" />{{ item.display }}</a
				>
				<div class="children" :class="classSelect(item.children)">
					<router-link v-for="(child, i) in item.children" :key="i" :to="child.to" @click="onClick"
						>{{ child.display }}
					</router-link>
				</div>
			</template>
			<router-link v-else :to="item.to" @click="onClick">
				{{ item.display }}
			</router-link>
		</template>
		<a href="#" @click.prevent="logout">Log Out</a>
	</div>
</template>

<script>
// Stores
import { useAuthStore } from '@/stores/AuthStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'TheNavSide',
	components: { BaseIcon },
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	computed: {
		// Convert shorthand into standard nav items
		itemsEnhanced() {
			const newItems = this.items
			newItems.forEach(item => {
				if (typeof item.to == 'string') item.to = { name: item.to }
				if (item.children) {
					item.children.forEach(child => {
						if (typeof child.to == 'string') child.to = { name: child.to }
					})
				}
			})
			return newItems
		},
	},
	methods: {
		// Return 'selected expand' class if current page is amongst children
		classSelect(children) {
			const active = children.find(child => {
				const routeName = typeof child.to == 'string' ? child.to : child.to.name
				// const regEx = new RegExp('^' + routeName)
				// return this.$route.name.match(regEx)
				return this.$route.name == routeName
			})
			return active ? 'selected expand' : ''
		},

		// Expand parent
		expand(ref) {
			const isExpanded = this.$refs[ref][0].classList.contains('expand')
			if (isExpanded) {
				this.$refs[ref][0].classList.remove('expand')
			} else {
				this.$refs[ref][0].classList.add('expand')
			}
		},

		// Close all trays when changing pages
		onClick() {
			for (let ref in this.$refs) {
				if (!this.$refs[ref][0].classList.contains('selected')) {
					this.$refs[ref][0].classList.remove('expand')
				}
			}
		},

		logout() {
			console.log('LOG OUT')
			const authStore = useAuthStore()
			authStore.logout(this.$router)
		},
	},
}
</script>

<style scoped lang="scss">
// Page Structure
#sidenav {
	flex: 0 02rem;
	background: $black-dark;
	color: #fff;
	padding-bottom: 0.4rem;
}

// Header & separators
#sidenav:deep() h1 {
	margin: 0.4rem 0 0.1rem 0.3rem;
}
#sidenav .sep {
	width: 100%;
	height: 0;
	border-bottom: dashed 0.01rem $bg;
	margin: 0.3rem 0 0.3rem 0;
	opacity: 0.2;
}

// Nav items
#sidenav a {
	height: 0.3rem;
	line-height: 0.3rem;
	display: block;
	width: 100%;
	color: $white-50;
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	box-sizing: content-box;
}
#sidenav a:not(.parent) {
	padding: 0;
	padding-left: 0.3rem;
	height: 0.5rem;
	line-height: 0.5rem;
}
#sidenav a.router-link-active {
	color: $primary;
	cursor: default;
}

// Parent items
#sidenav .parent:deep() .icn {
	vertical-align: top;
	fill: $white-50;
	margin: 0.05rem 0.05rem 0.05rem 0.24rem;
}
#sidenav .parent.expand:deep() .icn {
	transform: rotate(90deg);
}

// Parent items --> Selected state
#sidenav .parent.selected {
	color: #fff;
	font-weight: 500;
}
#sidenav .parent.selected,
#sidenav .parent.selected + .children {
	background: rgba(0, 0, 0, 0.3);
}

// Parent items --> Expanded state
#sidenav .parent:not(.selected).expand,
#sidenav .parent:not(.selected).expand + .children {
	// background: $black-10;
}
#sidenav .parent.expand {
	padding-bottom: 0.05rem;
}

// Children items
#sidenav .children {
	padding-bottom: 0.1rem;
}
#sidenav .children a {
	padding-top: 0;
	padding-left: 0.49rem;
	height: 0.4rem;
	line-height: 0.4rem;
}
#sidenav .parent:not(.expand) + .children {
	display: none;
}

/**
 * HOVER STATES
 */
@media (hover: hover) {
	#sidenav a:not(.router-link-active):not(.selected):hover {
		// background: $black-10;
		color: #fff;
	}
}
</style>
