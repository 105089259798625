<template>
	<div id="page-content">
		<!-- Sidenav -->
		<TheNavSide :items="navItems">
			<template v-slot:top>
				<h1>Docs</h1>
			</template>
		</TheNavSide>

		<!-- Body -->
		<div id="body">
			<router-view />
		</div>
	</div>
</template>

<script>
import TheNavSide from '@/components/TheNavSide'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocMain',
	components: {
		TheNavSide,
	},
	setup() {
		injectMetaData({
			title: 'Admin: Docs',
		})
	},
	data() {
		return {
			listType: '',
			navItems: [
				{
					display: 'Forms',
					children: [
						{
							display: 'Implementation',
							to: 'DocFormsImplementation',
						},
						{
							display: 'Preview',
							to: 'DocFormsPreview',
						},
						{
							display: 'Text',
							to: 'DocFormsText',
						},
						{
							display: 'Radio Buttons',
							to: 'DocFormsRadio',
						},
						{
							display: 'Dropdown',
							to: 'DocFormsDropdown',
						},
						{
							display: 'Labels',
							to: 'DocFormsLabels',
						},
						{
							display: 'Button',
							to: 'DocFormsButton',
						},
					],
				},
				{
					display: 'Components',
					children: [
						{
							display: 'Accordeon List',
							to: 'DocComponentsAccordeonList',
						},
						{
							display: 'Buttons',
							to: 'DocComponentsButtons',
						},
						{
							display: 'Icons',
							to: 'DocComponentsIcons',
						},
						{
							display: 'Sub Nav',
							to: 'DocComponentsSubNav',
						},
						{
							display: 'Side Nav',
							to: 'DocComponentsSideNav',
						},
						{
							display: 'Pagination',
							to: 'DocComponentsPagination',
						},
						{
							display: 'Table',
							to: 'DocComponentsTable',
						},
					],
				},
				{
					display: 'Modules',
					children: [
						{
							display: 'Dialogs',
							to: 'DocModulesDialogs',
						},
						{
							display: 'Flash Alerts',
							to: 'DocModulesFlash',
						},
						{
							display: 'Key Events',
							to: 'DocModulesKeyEvents',
						},
						{
							display: 'Copy Text',
							to: 'DocModulesCopyText',
						},
					],
				},
				{
					display: 'CSS',
					children: [
						{
							display: 'Lists',
							to: 'DocCssLists',
						},
					],
				},
				{
					display: 'Mechanics',
					children: [
						{
							display: 'Endorsing',
							to: 'DocMechanicsEndorsing',
						},
						{
							display: 'SSR Content',
							to: 'DocMechanicsSSRContentLoading',
						},
						{
							display: 'Year Format',
							to: 'DocMechanicsYearFormat',
						},
						{
							display: 'Non-Latin',
							to: 'DocMechanicsNonLatin',
						},
					],
				},
				{
					sep: true,
				},
				{
					display: 'Admin',
					to: 'Admin',
				},
				{
					display: 'Home',
					to: 'Home',
				},
			],
		}
	},
	computed: {
		// navItems() {
		// 	const selNav = this.navItemsAll[this.listType]
		// 	return selNav ? [...selNav, ...this.navItemsShared] : []
		// },
	},
}
</script>

<style lang="scss" scoped src="../../assets/css/side-nav-structure.scss"></style>
